import { render, staticRenderFns } from "./DesiredPrice.vue?vue&type=template&id=8ca5a182&scoped=true&"
import script from "./DesiredPrice.vue?vue&type=script&lang=ts&"
export * from "./DesiredPrice.vue?vue&type=script&lang=ts&"
import style0 from "./DesiredPrice.vue?vue&type=style&index=0&id=8ca5a182&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ca5a182",
  null
  
)

export default component.exports